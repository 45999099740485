import {ChangeDetectionStrategy, Component, ElementRef, OnInit, OnDestroy} from '@angular/core';
import {TranslationService} from './modules/i18n';
// language list
import {locale as enLang} from './modules/i18n/vocabs/en';
import {locale as chLang} from './modules/i18n/vocabs/ch';
import {locale as esLang} from './modules/i18n/vocabs/es';
import {locale as jpLang} from './modules/i18n/vocabs/jp';
import {locale as deLang} from './modules/i18n/vocabs/de';
import {locale as frLang} from './modules/i18n/vocabs/fr';
import {ThemeModeService} from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter } from 'rxjs';
import { AgUtilsComponent } from './ag-core/_agaram/ag-utils/components/ag-utils/ag-utils.component';
import { InternetConnectionService, LoggerService, ConsoleOverrideService, RouteService, LocalStorageService } from './ag-core/_agaram/ag-core';
import { CameraPermissionService } from './ag-core/_agaram/ag-shared/services/camera-permission.service';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { Platform } from '@angular/cdk/platform';
import { NotificationMessagingService } from './ag-core/_agaram/ag-core/services/notification-messaging.service';
import { Subscription } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { SwUpdate } from '@angular/service-worker';
@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports : [RouterModule, AgUtilsComponent],
  standalone : true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {

  private messageSubscription: Subscription;
  private isAppInForeground: boolean = true; // Track if the app is in the foreground

  constructor(
    private translationService: TranslationService,
    private modeService: ThemeModeService,
    private elementRef : ElementRef,
    private router : Router,
    private internetConnection : InternetConnectionService,
    private cameraPermissionService : CameraPermissionService,
    private viewPortRuler : ViewportRuler,
    private platform : Platform,
    private logger: LoggerService,
    private consoleOverrideService: ConsoleOverrideService,
    private routeService: RouteService,
    private notificationMessagingService: NotificationMessagingService,
    private swUpdate : SwUpdate,
    private storage : LocalStorageService
  ) {
    //this.overrideConsoleMethods();
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }

  ngOnInit() {
    //this.consoleOverrideService.initialize();
    console.log("appcomponent initialization");


    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered successfully:', registration.scope);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    }
    
    //this.listenForMessages(); // Call the method to subscribe to messages
    // Subscribe to incoming messages from the messaging service
    // this.messageSubscription = this.notificationMessagingService.currentMessage.subscribe(
    //   (message) => {
    //     if (message) {

    //       console.log('Message received:', message);
    //       // Handle the message as necessary

    //       if (!this.isAppInForeground && message.data && message.data.url) {
    //         // If the app is in the background, handle the notification
    //         if (message.data && message.data.url) {
    //           this.handleNotification(message.data.url);
    //         }
    //       }
    //       // if (message.data && message.data.url) {
    //       //   // Open the URL from the notification data in a new tab
    //       //   window.open(message.data.url, '_blank');
    //       // }
    //     }
    //   },

      
    //   (error) => {
    //     console.error('Error receiving message:', error);
    //   }
    // );

    // // Track when the app comes into focus
    // window.addEventListener('focus', () => {
    //   this.isAppInForeground = true;
    // });

    // // Track when the app goes out of focus
    // window.addEventListener('blur', () => {
    //   this.isAppInForeground = false;
    // });
    
    this.modeService.init();
    this.loadScript('https://checkout.razorpay.com/v1/checkout.js');
    if(this.platform.IOS) {
      this.scrollToTopIos();
    } else {
        this.scrollToTop();
    }
    // this.getCameraPermission();
    this.lockOrientation();
    this.internetConnection.isInternetAvailable.subscribe(
      isInternet => {
        // console.log('isInternet------------------------------->', isInternet);
        if(isInternet) {
          // this.router.navigate(['/menu']);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 200);
        } else {
            let isMobile = this.viewPortRuler.getViewportSize().width < 510;
            let isYesconVolunteerLogin = this.storage.getItem('is_yescon_volunteer_login');
            if(isMobile && !isYesconVolunteerLogin) {
              this.handleOffline();
            }
        }
      }
    );
  }

  private listenForMessages() {
    // Subscribe to messages
    this.messageSubscription = this.notificationMessagingService.currentMessage.subscribe(
      (message) => {
        if (message) {
          console.log('Message received in app component:', message);

          console.log("this.isAppInForeground :: ", this.isAppInForeground);

          // const notificationId = createNotificationId(payload);

          // // Check if the notification is already processed
          // if (displayedNotifications.has(notificationId)) {
          //   console.log('Duplicate notification, ignoring:', notificationId);
          //   return; // Ignore duplicate notifications
          // }

          // // Add the notification ID to the set
          // displayedNotifications.add(notificationId);

          // Check if the app is in the background
          if (this.isAppInForeground && message.data) {

            console.log("Attempting to show custom notification:", message);

            // Display notification manually when the app is in the foreground

            const notificationOptions = {
              body: message.data.body,
              icon: message.data.icon, // Replace with your default icon path
              requireInteraction: true,
              data: {
                url: message.data?.url || message.data?.click_action
              }
            };
  
            console.log("notificationOptions :: :: ",  notificationOptions);
  
            const notificationTitle = message.data.title;

            console.log("notificationTitle :: ", notificationTitle);
  
            if (Notification.permission === 'granted') {
              console.log("Permission granted in appcomponet");
              navigator.serviceWorker.ready.then((registration) => {
                registration.showNotification(notificationTitle, notificationOptions);
              });
            } else {
              console.log('Notification permission not granted.');
            }
            // const notification = new Notification(notificationTitle, notificationOptions);

            // console.log(">>>>>>>>>>>>>>>>>>> ", notification);
  
            // // Handle click on notification
            // notification.onclick = (event) => {
            //   event.preventDefault(); // Prevent default action (opening in a new tab)
            //   if (notificationOptions.data.url) {
            //     window.open(notificationOptions.data.url, '_blank');
            //   }
            // };
          } else if (!this.isAppInForeground && message.data && message.data.url) {
            console.log("App Component Handle Notification :: :: ");
            // Handle notification click for background messages
            this.handleNotification(message.data.url);
          }
        }
      },
      (error) => {
        console.error('Error receiving message:', error);
      }
    );
  }

  private handleNotification(url: string) {
    console.log('Handling notification with URL:', url);
    //window.open(url, '_blank'); // Open the URL in a new tab
    if (!this.isAppInForeground) {
      // If app is not in foreground, open the notification URL
      window.open(url, '_blank');
    } else {
      // Otherwise, perform in-app navigation
      this.router.navigateByUrl(url);
    }
  }

  private trackAppState() {
    // Optional: You can track whether the app is in the foreground or background
    document.addEventListener('visibilitychange', () => {

      this.isAppInForeground = !document.hidden;
    });
  }

  ngOnDestroy() {
    // Clean up the subscription to avoid memory leaks
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    // window.removeEventListener('focus', () => this.isAppInForeground = true);
    // window.removeEventListener('blur', () => this.isAppInForeground = false);
  }

//   overrideConsoleMethods() {
//     this.router.events.subscribe((event) => {
//       if (event instanceof NavigationEnd) {
//     console.log("overrideConsoleMethods :: ");
//     const originalConsoleLog = console.log;
//     const originalConsoleWarn = console.warn;
//     const originalConsoleError = console.error;
//     const originalConsoleInfo = console.info;
//     // Override console.log
//     console.log = (message?: any, ...optionalParams: any[]) => {
//       if (!this.logger) {
//         originalConsoleLog('Logger not initialized', message, ...optionalParams);
//       } else {
//         this.logger.info(message, ...optionalParams);
//       }
//       originalConsoleLog.apply(console, [message, ...optionalParams]); // Preserve default behavior
//     };

//     // Override console.warn
//     console.warn = (message?: any, ...optionalParams: any[]) => {
//       if (!this.logger) {
//         originalConsoleWarn('Logger not initialized', message, ...optionalParams);
//       } else {
//         this.logger.warn(message, ...optionalParams);
//       }
//       originalConsoleWarn.apply(console, [message, ...optionalParams]);
//     };

//     // Override console.error
//     console.error = (message?: any, ...optionalParams: any[]) => {
//       if (!this.logger) {
//         originalConsoleError('Logger not initialized', message, ...optionalParams);
//       } else {
//         this.logger.error(message, ...optionalParams);
//       }
//       originalConsoleError.apply(console, [message, ...optionalParams]);
//     };

//     // Optional: Override console.info
//     console.info = (message?: any, ...optionalParams: any[]) => {
//       if (!this.logger) {
//         originalConsoleInfo('Logger not initialized', message, ...optionalParams);
//       } else {
//         this.logger.info(message, ...optionalParams);
//       }
//       originalConsoleInfo.apply(console, [message, ...optionalParams]);
//     };
//   }
// });
//   }

  async getCameraPermission () {
    // console.log('************************* Getting Camera Permission ***************************');
    await this.cameraPermissionService.requestCameraAccess();
  }

  private loadScript(src : string) {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = () => {
      console.log('Script loaded successfully.');
    };
    script.onerror = (error) => {
      console.error('Script load error:', error);
    };
    this.elementRef.nativeElement.appendChild(script);
  }

  scrollToTop() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
        // console.log('scroll to--------------------------------------------->');
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 200);
    });
  }

  scrollToTopIos() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
        // console.log('scroll to--------------------------------------------->');
        setTimeout(() => {
          if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.scrollToTop) {
            window.webkit.messageHandlers.scrollToTop.postMessage();
          }
        }, 200);
    });
  }

  handleOffline() {
    this.router.navigate(['/offline-menu']);
  }

  lockOrientation() {
    if ('screen' in window && 'orientation' in screen) {
      const orientation = screen.orientation as any;
      if(orientation && orientation.lock) {
        orientation.lock('portrait').then(
          () => {
            console.log('********************* Orientation Locked in Portrait ************************');
          }
        ).catch(err => {
            console.log('********************* Failed to lock Orientation ***********************', err);
        });
      } else {
          console.error('********************* Orientation Lock is not Available *********************');
      }
    } else {
        console.error('********************* Screen Orientation API is not supported *********************');
    }
  }
}